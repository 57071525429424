import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const SectionForm = styled.div`
  width: 30%;
  position: fixed;
  height: 100vh;
  left: 0;
  overflow-y: scroll;
  background-color: #fff;

  @media (max-width: 1024px) {
    width: 100%;
    position: relative;
  }
`;

export const Content = styled.div`
  padding: 80px 100px;

  @media (max-width: 1024px) {
    padding: 40px 20px;
  }
`;

export const FormContent = styled.form`
  padding: 30px 0;
`;

export const FormGroup = styled.div``;

export const TermDescription = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #4e4b59;
  margin: 20px 0;
`;

export const PasswordRecovery = styled.a`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #373737;
  margin: 0 0 24px 0;
  display: inline-block;
  text-decoration: none;
`;

export const AccountExists = styled.a`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #373737;
  margin: 24px 0;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  width: 100%;
`;

export const PageLogo = styled.img`
  width: 180px;
`;

export const PageTitle = styled.h1`
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 140%;
  color: #000000;
  margin-bottom: 24px;
`;

export const PageDescription = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 150%;
  color: #000000;
`;

export const SectionBanner = styled.div`
  width: 70%;
  position: fixed;
  height: 100vh;
  right: 0;
  background-size: cover;
  background-position: center;
  background-image: url(${require("../../../assets/images/login/background-login-3.jpg")});

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonAccessToken = styled.button`
  width: 100%;
  background-color: orange;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin-bottom: 14px;
  font-weight: bold;
`; 