import { useEffect, useState } from "react";
import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  InfoContainerStatus,
  InfoContainerText,
  PageLocationTitle,
  PageTitle,
  PageTitleContainer,
  StatusButton,
  SubscriptionContainer,
  SubscriptionContent,
  SubscriptionDescription,
  SubscriptionItem,
  SubscriptionList,
  SubscriptionTitle,
} from "../../profile/subscriptions/styles";
import api from "../../../services/api";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Loading from "../../../components/atoms/loading";
import React from "react";

import { useParams } from "react-router-dom";
import { StringToSlug, StringToSlugPraia } from "../../../utils/SlugConverter";

const AllCheckCategoriesCopaInTheGuardDeJiuJitsu = () => {

  //let senha = "teste"

  let password = {
    "torneio": "copaintheguard",
    "credenciais": {
      "usuario": "copaintheguard",
      "senha": "copaintheguard@2025"
    }
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuthenticate();

  const [showData, setShowData] = useState(false);

  const { slug } = useParams();
  const [tournament, setTournament] = useState<any>({});
  const [subscription, setSubscription] = useState<any>([]);
  const [senha, setSenha] = useState(""); // Estado para armazenar o valor da senha digitada
  const [isAuthorized, setIsAuthorized] = useState(false); // Estado para controlar a exibição do relatório

  const handleCheckPassword = () => {
    if (senha === password.credenciais.senha) {
      setIsAuthorized(true); // Exibe o relatório se a senha estiver correta
    } else {
      alert("Senha incorreta"); // Exibe um alerta se a senha estiver errada
    }
  };

  
  const beltOrder: { [key: string]: number } = {
    branca: 1,
    cinza: 2,
    amarela: 3,
    laranja: 4,
    verde: 5,
    azul: 6,
    roxa: 7,
    marrom: 8,
    preta: 9,
  };

  const categoryOrder: { [key: string]: number } = {
    "pré-mirim": 1,
    "mirim a": 2,
    "mirim b": 3,
    "infantil a": 4,
    "infantil b": 5,
    "infanto juvenil": 6,
    juvenil: 7,
    adulto: 8,
    "master 1 e 2": 9,
    "master 3, 4 e 5": 10,
  };

  const weightOrder: { [key: string]: number } = {
    galo: 1,
    pluma: 2,
    pena: 3,
    leve: 4,
    médio: 5,
    "meio pesado": 6,
    pesado: 7,
    "super pesado": 8,
    pesadíssimo: 9,
    absoluto: 10,
  };

  const ageOrder: { [key: string]: number } = {
    "4": 1,
    "5": 1,
    "6": 2,
    "7": 2,
    "8": 3,
    "9": 3,
    "10": 4,
    "11": 4,
    "12": 5,
    "13": 5,
    "14": 6,
    "15": 6,
    "16": 7,
    "17": 7,
    "18-29": 8,
    "30-40": 9,
    "41-200": 10,
  };

  function getAgeOrder(age: string | number): number {
    const ageNum = typeof age === "string" ? parseInt(age) : age;

    if (ageNum >= 4 && ageNum <= 5) return ageOrder["4"];
    if (ageNum >= 6 && ageNum <= 7) return ageOrder["6"];
    if (ageNum >= 8 && ageNum <= 9) return ageOrder["8"];
    if (ageNum >= 10 && ageNum <= 11) return ageOrder["10"];
    if (ageNum >= 12 && ageNum <= 13) return ageOrder["12"];
    if (ageNum >= 14 && ageNum <= 15) return ageOrder["14"];
    if (ageNum >= 16 && ageNum <= 17) return ageOrder["16"];
    if (ageNum >= 18 && ageNum <= 29) return ageOrder["18-29"];
    if (ageNum >= 30 && ageNum <= 40) return ageOrder["30-40"];
    if (ageNum >= 41) return ageOrder["41-200"];

    return 0; // Default case, should not happen if all ages are covered
  }

  interface User {
    id: number;
    name: string;
    phone: string;
    email: string;
    gym: string;
    genre: string;
  }

  interface Fighter {
    id: number;
    weight: string;
    genre: string;
    age: string;
    belt: string;
    category: string;
    valueSubscription: string;
    status: boolean;
    created_at: string;
    updated_at: string;
    userId: number;
    fightersId: number | null;
    tournamentId: number;
    fighters: any;
    user: User;
  }

  function converterSubscriptions(array: any) {
    const subscriptions: {
      id: any;
      weight: any;
      age: any;
      gender: any;
      genderBool: string;
      belt: any;
      category: any;
      name: any;
      gymAbre: any;
      gym: any;
      status: any;
      value: any;
      categoryw: any;
    }[] = [];
    array.forEach((item: any) => {
      let fighterBelt = item.belt;
      if (item.belt === "laranja" || item.belt === "verde") {
        fighterBelt = "laranja e verde";
      }
      // else if (item.belt === "cinza" || item.belt === "amarela") {
      //   fighterBelt = "cinza e amarela";
      // }
      let category;
      category = StringToSlug(
        `${fighterBelt}/${item.genre}/${item.weight}/${item.category}`
      );

      if (item.fighters && item.status) {
        subscriptions.push({
          id: item.id,
          weight: item.weight,
          age: item.age,
          gender: item.fighters.genre,
          genderBool: item.fighters.genre === "Masculino" ? "Falso" : "Verdadeiro",
          belt: item.belt,
          category: item.category,
          name: item.fighters.name,
          gymAbre: item.fighters.gym.substring(0, 12),
          gym: item.fighters.gym,
          status: item.status,
          categoryw: category,
          value: item.valueSubscription,
        });
      }

      if (!item.fighters && item.status) {
        subscriptions.push({
          id: item.id,
          weight: item.weight,
          age: item.age,
          gender: item.user.genre,
          genderBool: item.user.genre === "masculino" ? "Falso" : "Verdadeiro",
          belt: item.belt,
          category: item.category,
          name: item.user.name,
          gymAbre: item.user.gym.substring(0, 12),
          gym: item.user.gym,
          status: item.status,
          categoryw: category,
          value: item.valueSubscription,
        });
      }
      // order by age and weight
    });

    function categorizeParticipants(participants: any) {
      let categories: any = {};

      participants.forEach((participant: any) => {
        // Define the category based on participant properties
        let fighterBelt = participant.belt;
        if (participant.belt === "laranja" || participant.belt === "verde") {
          fighterBelt = "laranja e verde";
        }
        // let fighterBelt = participant.belt;
        // if (participant.belt === "laranja" || participant.belt === "verde") {
        //   fighterBelt = "laranja e verde";
        // }


        // else if (
        //   participant.belt === "cinza" ||
        //   participant.belt === "amarela") {
        //   fighterBelt = "cinza e amarela";
        // }
        // console.log("ENtrOU")
        // console.log("FAiXA: " + fighterBelt)

        let category;

        category = {
          title: StringToSlug(
            `${participant.category}/${participant.genre}/${fighterBelt}/${participant.weight}`
          ),
        };

        // If this category doesn't exist, create it
        if (!categories[category.title]) {
          categories[category.title] = [];
        }

        // Add participant to the correct category
        categories[category.title].push(participant);
      });

      // Convert categories object into array of objects with structure: {title: title, data: participants}
      let categoriesArray = Object.keys(categories).map((title) => {
        return {
          title: title,
          data: categories[title],
          age: getAgeOrder(categories[title][0].age),
        };
      });
      // Sort categories by age and weight
      categoriesArray.sort(function (a: { age: number }, b: { age: number }) {
        if (a.age > b.age) {
          return 1;
        }
        if (a.age < b.age) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      setSubscription(categoriesArray);
    }

    categorizeParticipants(array);
  }

  useEffect(() => {
    setLoading(true);

    api
      .get("/tournaments/" + slug)
      .then(({ data }) => {
        setTournament(data);
      })
      .catch(() => {
        toast.error("Não foi carregar os dados do torneio.");
      });

    api
      .get("/tournaments/enrollments/list-all/public/" + slug, {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setLoading(false);

        const sortedArray = data
          .sort((a: Fighter, b: Fighter) => {
            if (a.genre === "feminino" && b.genre !== "feminino") return -1;
            if (a.genre !== "feminino" && b.genre === "feminino") return 1;

            if (a.genre === b.genre) {
              if (beltOrder[a.belt] !== beltOrder[b.belt]) {
                return beltOrder[a.belt] - beltOrder[b.belt];
              }

              if (categoryOrder[a.category] !== categoryOrder[b.category]) {
                return categoryOrder[a.category] - categoryOrder[b.category];
              }

              return weightOrder[a.weight] - weightOrder[b.weight];
            }

            return 0;
          })
          .filter((item: Fighter) => item.status === true);
        setData(sortedArray);
        if (data && subscription.length === 0) converterSubscriptions(sortedArray);

        setShowData(true);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados das inscrições.");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  let quantidadeInscrcaoPagas = data.length;
  let quatidadeDeChaves = subscription.filter((item: any) => item.data.length > 0).length;
  let quatidadeDeChavesSemLuta = subscription.filter(
    (item: any) => item.data.length === 1
  ).length;

  let medalhasBronze = subscription.filter((item: any) => item.data.length > 2).length;
  let medalhasPrata =
    medalhasBronze + subscription.filter((item: any) => item.data.length === 2).length;
  let medalhasOuro =
    medalhasPrata + subscription.filter((item: any) => item.data.length === 1).length;

  console.log(medalhasOuro, medalhasPrata, medalhasBronze);

  return (
    <>
      <NavBar />
      <Container>
        <Content id="todaslutas">
          <PageTitleContainer>
            <a href="#semlutas">Ir para Chaves Sem Lutas</a>
            <br />
            <a href="#relatorioTorneio">Ir para Relatório do Torneio</a>
            <PageLocationTitle>Checagem</PageLocationTitle>
            <h2 style={{ color: "red" }}>
              {" "}
              OBS: SOMENTE INSCRIÇÕES PAGAS APARECEM NA CHECAGEM
            </h2>
            <PageTitleContainer>
              <h2 style={{ color: "black" }}>TODAS AS LUTAS:</h2>
            </PageTitleContainer>
          </PageTitleContainer>

          <SubscriptionContainer>
            {/* renderizar as inscrições para cada categoria */}
            {subscription.map((item: any) => {
              console.log(item);
              // mostrar as inscrições apenas se houver atletas na categoria atual

              // item.data.sort(function (a: { age: number }, b: { age: number }) {
              //   if (a.age > b.age) {
              //     return 1;
              //   }
              //   if (a.age < b.age) {
              //     return -1;
              //   }
              //   // a must be equal to b
              //   return 0;
              // });

              if (showData) {
                return (
                  <React.Fragment key={item.title}>
                     <br></br>
                     <PageTitle>{item.title}</PageTitle>
                    {item.data.map((value: any, index: number) => (
                      <SubscriptionList key={value.id}>
                        <SubscriptionItem>
                      
                          <SubscriptionContent>
                         
                            {/* <SubscriptionTitle>
                              Inscrição Nº {value.id} -{tournament.name}
                            </SubscriptionTitle> */}
                            {/* <br /> */}
                            <SubscriptionDescription>
                              <InfoContainerText >
                                
                              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
    <div style={{ flexGrow: 1 }}>
        <span>
            <b>{index + 1}. </b>
            {(value.fighters ? value.fighters.name : value.user.name).toLocaleUpperCase()}{" "}
        </span>
    </div>
    <div style={{ flexShrink: 0, textAlign: "right" }}>
        <span>
            {(value.fighters ? value.fighters.gym : value.user.gym).toLocaleUpperCase()}
        </span>
    </div>
</div>
                               
                                {/* <span>Professor: ORLEY LOBATO</span><br/> */}
                              </InfoContainerText>

                            </SubscriptionDescription>
                          </SubscriptionContent>
                        </SubscriptionItem>
                        
                      </SubscriptionList>
                     
                    ))}
                     <br></br> <br></br>
                  </React.Fragment>
                  
                );
                <br></br>
              } else {
                // não há atletas nesta categoria, então não renderizar nada
                return null;
              }
            })}
          </SubscriptionContainer>
        </Content>

        <Content id="semlutas">
          <a href="#todaslutas">Ir para Todas as lutas</a>
          <br />
          <a href="#relatorioTorneio">Ir para Relatório do Torneio</a>
          <PageTitleContainer>
            <h2 style={{ color: "black" }}>
              {subscription.filter((item: any) => item.data.length === 1).length} CHAVES
              SEM LUTAS:
            </h2>
          </PageTitleContainer>

          <SubscriptionContainer>
            {/* renderizar as inscrições para cada categoria */}
            {subscription
              .filter((item: any) => item.data.length === 1)
              .map((item: any) => {
                // mostrar as inscrições apenas se houver atletas na categoria atual

                item.data.sort(function (a: { age: number }, b: { age: number }) {
                  if (a.age > b.age) {
                    return 1;
                  }
                  if (a.age < b.age) {
                    return -1;
                  }
                  // a must be equal to b
                  return 0;
                });

                if (showData) {
                  return (
                    <React.Fragment key={item.title}>
                      <PageTitle>{item.title}</PageTitle>
                      {item.data.map((value: any, index: number) => (
                        <SubscriptionList key={value.id}>
                          <SubscriptionItem>
                            <SubscriptionContent>
                              <SubscriptionTitle>
                                Inscrição Nº {value.id} -{tournament.name}
                              </SubscriptionTitle>
                              {/* <br /> */}
                              <SubscriptionDescription>
                                <InfoContainerText>
                                  {/* <b>Inscrição: {index + 1}°</b>{" "} */}
                                  <span>sem luta</span>
                                  <span>
                                    <b>Nome:</b>{" "}
                                    {value.fighters
                                      ? value.fighters.name
                                      : value.user.name}
                                  </span>
                                  <span>
                                    <b>Academia: </b>{" "}
                                    {value.fighters ? value.fighters.gym : value.user.gym}
                                  </span>
                                  <span>
                                    <b>Faixa: </b> {value.belt}
                                  </span>
                                  {/* <span>Professor: ORLEY LOBATO</span><br/> */}
                                </InfoContainerText>
                                <InfoContainerStatus>
                                  <StatusButton status={value.status}>
                                    <span>
                                      {value.status
                                        ? "Pagamento efetuado"
                                        : "Pendente de pagamento"}
                                    </span>
                                  </StatusButton>
                                </InfoContainerStatus>
                              </SubscriptionDescription>
                            </SubscriptionContent>
                          </SubscriptionItem>
                        </SubscriptionList>
                      ))}
                    </React.Fragment>
                  );
                } else {
                  // não há atletas nesta categoria, então não renderizar nada
                  return null;
                }
              })}
          </SubscriptionContainer>
        </Content>

        <Content id="relatorioTorneio">
          <a href="#todaslutas">Ir para Todas as lutas</a>
          <br />
          <a href="#semlutas">Ir para Chaves Sem Lutas</a>
<br></br>
          {/* Campo de entrada para a senha */}
          <input
            type="password"
            placeholder="Digite a senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)} // Atualiza o estado com o valor digitado
          />
          
           <button onClick={handleCheckPassword}>Entrar</button> {/* Botão para verificar a senha */}
          
           {isAuthorized && (
            <>
              <h1>Relatório de Inscrições</h1>
              <h2>{quantidadeInscrcaoPagas} Inscrições</h2>
              <h2>{quatidadeDeChaves} Total de Chaves</h2>
              <h2>{quatidadeDeChavesSemLuta} Chaves sem Luta</h2>
              <h2>{quatidadeDeChaves - quatidadeDeChavesSemLuta} Chaves com luta</h2>

              <h1>Relatório de Medalhas</h1>
              <h2>{medalhasOuro} Ouro</h2>
              <h2>{medalhasPrata} Prata</h2>
              <h2>{medalhasBronze} Bronze</h2>
            </>
          )}

        </Content>
      </Container>
    </>
  );
};

export default AllCheckCategoriesCopaInTheGuardDeJiuJitsu;
