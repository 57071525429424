import { useNavigate } from "react-router-dom";
import { TournamentBanckAccount, TournamentsAccountContainer, TournamentsAccountDivider, TournamentTitle } from "./styles";

const TournamentsAccount = ({ tournament }: any) => {

    const navigate = useNavigate();

    const handleSelectTournament = () =>{
        navigate(`/tournament/connect-bank/edit-linked-tournament?tournament=${tournament?.id}&account=${tournament.bankAccount?.id || 0}`);
    }

    return <TournamentsAccountContainer onClick={handleSelectTournament}>
        <TournamentTitle>
            <h4>{tournament.name}</h4>
        </TournamentTitle>

        <TournamentsAccountDivider/>

        <TournamentBanckAccount>
            {
                tournament.bankAccount ? 
                <h4>{tournament.bankAccount.name} - {tournament.bankAccount.bank.name}</h4> :
                <h4>NENHUMA CONTA VINCULADA</h4>
            }
        </TournamentBanckAccount>
    </TournamentsAccountContainer>
}

export default TournamentsAccount;