import { ConnectAccountArticle, ConnectAccountContainer, ConnectAccountImage } from "./styles"
import PagBankLogo from '../../../../../assets/images/banks/pagbank-logo.png';
import { useNavigate } from "react-router-dom";

const BankAccount = ({account}: any) => {

    const navigate = useNavigate();

    const handleBankAccountEdit = () => {
        navigate(`/tournament/connect-bank/edit-bank/${account.id}`);
    }

    return <ConnectAccountContainer onClick={handleBankAccountEdit}>
        <ConnectAccountImage src={PagBankLogo} alt="Logo do banco" />
        <h4>PagBank</h4>
        <ConnectAccountArticle>
            <p><b>Nome:</b> {account.name} </p>
            <p><b>Descrição:</b> {account.description} </p>
        </ConnectAccountArticle>
    </ConnectAccountContainer>
}

export default BankAccount;