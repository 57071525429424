import NavBar from "../../../components/organisms/navbar";
import {
  Container,
  Content,
  PaymentContainer,
  Title,
  Title2,
  InstructionTitle,
  InstructionList,
  InstructionItem,
  InstructionStep,
  InstructionItemContent,
  StyledLink,
  HighlightedLink,
} from "../styles";
import { FaWhatsapp } from "react-icons/fa";
import { useParams } from "react-router-dom";
import React from "react";

const PaymentEnrollmentEtapaOriximinaXxxiiCopaOrleyLobatoDeJiuJitsu = () => {
  const { slug, id } = useParams();
  switch (slug) {
    case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
    case "etapa-oriximina-xxxii-copa-orley-lobato-jiu-jitsu-oriximina-pa-gi":
      return (
        <Container>
          <NavBar />
          <Content>
            <PaymentContainer>
              <Title>Obrigado por fazer a sua inscrição no</Title>
              <Title2>
                {`Estadual GF Team Amazonas Jiu Jitsu ${id!.split("-").length > 1 ? "": "GI"}  `.toLocaleUpperCase()}
              </Title2>
              <p style={{ color: "red", marginBottom: "0px" }}>
                {
                  id?.split("-").map(i =>  <p><b>Inscrição N° {i}</b></p>)
                }
              </p>
              <InstructionTitle>Instruções:</InstructionTitle>

              <InstructionList>
                {/* <InstructionItem color="#f0f4c3"> */}
                <InstructionItem color="rgb(210, 210, 210)">
                  <InstructionStep>Passo 1</InstructionStep>
                  <InstructionItemContent>
                    Colocar o número da inscrição ({" "}
                    <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na
                    descrição do PIX.
                  </InstructionItemContent>
                </InstructionItem>

                {/* <InstructionItem color="#e3f2fd"> */}
                <InstructionItem color="rgb(210, 210, 210)">
                  <InstructionStep>Passo 2</InstructionStep>
                  <InstructionItemContent>
                    Efetuar o pagamento através do PIX para:
                    <ul style={{ listStyle: "none" }}>
                      <br />
                      <li>Banco Inter</li>
                      <li>Tipo de Chave PIX E-MAIL</li>
                      <li>
                        <b>Pix: financeiro@torneiofacil.com</b>
                      </li>
                      <li>Thiago Souza Dellarmelina</li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(210, 210, 210)">
                  {/* <InstructionItem color="#ffecb3"> */}
                  <InstructionStep>Passo 3</InstructionStep>
                  <InstructionItemContent>
                    Enviar o comprovante de pagamento (com número da inscrição descrição)
                    para:
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <br />
                        <StyledLink
                          target="_blank"
                          href={`https://whatsa.me/5511944563515/?t=Olá,%20Financeiro%20Torneio%20Fácil!%0A%0AEstou+enviando+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AESTADUAL+GF+TEAM+AMAZONAS+DE+JIU+JITSU+GI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}*`}
                        >
                          +55 (11) 94456-3515 <FaWhatsapp />
                        </StyledLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(210, 210, 210)">
                  {/* <InstructionItem color="#c8e6c9"> */}
                  <InstructionStep>Passo 4</InstructionStep>
                  <InstructionItemContent>
                    Acompanhe o status do pagamento em:
                    <br />
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <HighlightedLink href="/profile/subscription">
                          Minhas Inscrições
                        </HighlightedLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>
              </InstructionList>
            </PaymentContainer>
          </Content>
        </Container>
      );
    case "copa-amizade-de-jiu-jitsu-2024-ginasio-de-esportes-amario-vieira-da-costa-umuarama-pr":
    case "estadual-gf-team-amazonas-jiu-jitsu-manaus-am-nogi":
      return (
        <Container>
          <NavBar />
          <Content>
            <PaymentContainer>
              <Title>Obrigado por fazer a sua inscrição no</Title>
              <Title2>
                {`Estadual GF Team Amazonas Jiu Jitsu ${id!.split("-").length > 1 ? "": "NOGI"}  `.toLocaleUpperCase()}
              </Title2>
              <p style={{ color: "red", marginBottom: "0px" }}>
                {
                  id?.split("-").map(i => <p><b>Inscrição N° {i}</b></p>)
                }
              </p>
              <InstructionTitle>Instruções:</InstructionTitle>

              <InstructionList>
                {/* <InstructionItem color="#f0f4c3"> */}
                <InstructionItem color="rgb(240, 240, 240)">
                  <InstructionStep>Passo 1</InstructionStep>
                  <InstructionItemContent>
                    Coloca o número da inscrição ({" "}
                    <b style={{ color: "red", marginBottom: "30px" }}>{id}</b> ) na
                    descrição do PIX.
                  </InstructionItemContent>
                </InstructionItem>

                {/* <InstructionItem color="#e3f2fd"> */}
                <InstructionItem color="rgb(230, 230, 230)">
                  <InstructionStep>Passo 2</InstructionStep>
                  <InstructionItemContent>
                    Efetuar o pagamento através do PIX para:
                    <ul style={{ listStyle: "none" }}>
                      <br />
                      <li>Banco Inter</li>
                      <li>Tipo de Chave PIX E-MAIL</li>
                      <li>
                        <b>Pix: financeiro@torneiofacil.com</b>
                      </li>
                      <li>Thiago Souza Dellarmelina</li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(220, 220, 220)">
                  {/* <InstructionItem color="#ffecb3"> */}
                  <InstructionStep>Passo 3</InstructionStep>
                  <InstructionItemContent>
                    Enviar o comprovante de pagamento (com número da inscrição descrição)
                    para:
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <br />
                        <StyledLink
                          target="_blank"
                          href={`https://whatsa.me/5511944563515/?t=Olá,%20Financeiro%20Torneio%20Fácil!%0A%0AEstou+enviando+o+comprovante+de+pagamento+referente+a+inscri%C3%A7%C3%A3o+no+%2AESTADUAL+GF+TEAM+AMAZONAS+DE+JIU+JITSU+NOGI%2A%0A%0A%2AInscri%C3%A7%C3%A3o+N%C2%B0+${id}*`}
                        >
                          (11) 94456-3515 <FaWhatsapp />
                        </StyledLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>

                <InstructionItem color="rgb(210, 210, 210)">
                  {/* <InstructionItem color="#c8e6c9"> */}
                  <InstructionStep>Passo 4</InstructionStep>
                  <InstructionItemContent>
                    Acompanhe o status do pagamento em:
                    <br />
                    <ul style={{ listStyle: "none" }}>
                      <li>
                        <HighlightedLink href="/profile/subscription">
                          Minhas Inscrições
                        </HighlightedLink>
                      </li>
                    </ul>
                  </InstructionItemContent>
                </InstructionItem>
              </InstructionList>
            </PaymentContainer>
          </Content>
        </Container>
      );
    default:
      return <h1>Pagamento não liberado para esse torneio</h1>;
  }
};
export default PaymentEnrollmentEtapaOriximinaXxxiiCopaOrleyLobatoDeJiuJitsu;
