import NavBar from "src/components/organisms/navbar";
import { Container, Content, PageLocationTitle, PageTitleContainer } from "../styles";
import { ConnectBankContainer, ConnectBankContent, ConnectBankLink } from "./styles";
import BankAccount from "./components/banckAccount";
import { useEffect, useState } from "react";
import api from "src/services/api";
import useAuthenticate from "src/hooks/useAuthenticate";

const ConnectPayment = () => {
    const { getToken } = useAuthenticate();
    const [bankAccounts, setBankAccounts] = useState([]);

    useEffect(() => {
        api.get(
            "/manager/connect/bankAccount",
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            }
        ).then((data) => {
            setBankAccounts(data.data);
        });
    }, []);

    return <>
        <NavBar />
        <Container>
            <Content>
                <PageTitleContainer>
                    <PageLocationTitle>Gerenciar Contas de Bancos</PageLocationTitle>
                    <ConnectBankLink href="/tournament/connect-bank/new-bank">Cadastrar Banco</ConnectBankLink>
                    <ConnectBankLink href="/tournament/connect-bank/linked-tournament">Vincular conta ao torneio</ConnectBankLink>
                </PageTitleContainer>

                <ConnectBankContainer>
                    <ConnectBankContent>
                        {
                            bankAccounts.sort((a: any, b: any) => a.id - b.id).map(bankData => (
                                <BankAccount account={bankData} />
                            ))
                        }
                    </ConnectBankContent>
                </ConnectBankContainer>
            </Content>
        </Container>
    </>
}

export default ConnectPayment;