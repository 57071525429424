import styled from "styled-components";

export const TournamentsAccountContainer = styled.div`
    display: flex;
    align-items: center;
    max-height: 300px;
    min-height: 100px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 8px 16px;
    cursor: pointer;
`;

export const TournamentsAccountDivider = styled.div`
    height: 70px;
    border: 1px solid black;
`;

export const TournamentTitle = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TournamentBanckAccount = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;