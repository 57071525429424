import useAuthenticate from "src/hooks/useAuthenticate";
import ProfileButton from "../../components/atoms/profile-button";
import Footer from "../../components/organisms/footer";
import NavBar from "../../components/organisms/navbar";
import {
  Container,
  Content,
  PageLocationTitle,
  PageTitleContainer,
  ProfileContainer,
  ProfileList,
} from "./styles";
import api from "src/services/api";
import React from "react";
import Loading from "src/components/atoms/loading";

const acessos = [
  {
    label: "Torneios",
    url: "/",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/minha-equipe.png",
  },
  {
    label: "Minhas Inscrições",
    url: "/profile/subscription",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/inscricoes-eventos.png",
  },
  {
    label: "Meus Atletas",
    url: "/profile/athletes",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/meus-atletas.png",
  },
];

const outrosAcessos = [
  {
    label: "Meus Dados",
    url: "/profile/edit/user",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/meu-perfil.png",
  },
];

const acessosAdm = [
  {
    label: "Gerenciar Torneios",
    url: "/admin/subscriptions",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/minha-equipe.png",
  },
  {
    label: "Cadastrar Contas de Banco",
    url: "/tournament/connect-bank",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/minha-equipe.png",
  }
];
const myTournamentsList = [
  {
    label: "Gerenciar Pagamentos",
    url: "/tournament/subscriptions",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/minha-equipe.png",
  },
  {
    label: "Cadastrar Campeonato",
    url: "/tournament/new",
    icon: "https://soucompetidor.com.br/static/v2/css/img/meu-perfil/atleta/minha-equipe.png",
  },
];
const ProfileEvent = () => {
  const { account, getToken }: any = useAuthenticate();
  const [myTournaments, setMyTournaments] = React.useState<any[]>([]);
  const [loading, setLoading] =  React.useState(true);


  React.useEffect(() => {
    const isManager = async () => {
      await api.get("/managers/tournaments", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then((response) => {
        response.data.map((tournament: any) =>
          setMyTournaments([...myTournaments, tournament.slug])
          )
        setLoading(false);
        })
        .catch(() => setLoading(false));

    };
    isManager();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading text="Aguarde, estamos processando os dados." />;

  return (
    <>
      <NavBar />
      <Container>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Acessos Rápidos</PageLocationTitle>
          </PageTitleContainer>
          <ProfileContainer>
            <ProfileList>
              {acessos &&
                acessos.map((buttons: any) => (
                  <ProfileButton
                    label={buttons.label}
                    icon={buttons.icon}
                    url={buttons.url}
                    hasError={false}
                    error="Este campo é obrigatório"
                  />
                ))}
            </ProfileList>
          </ProfileContainer>
        </Content>
        <Content>
          <PageTitleContainer>
            <PageLocationTitle>Outros Acessos</PageLocationTitle>
          </PageTitleContainer>
          <ProfileContainer>
            <ProfileList>
              {outrosAcessos &&
                outrosAcessos.map((buttons: any) => (
                  <ProfileButton
                    label={buttons.label}
                    icon={buttons.icon}
                    url={buttons.url}
                    hasError={false}
                    error="Este campo é obrigatório"
                  />
                ))}
            </ProfileList>
          </ProfileContainer>
        </Content>

        {myTournaments.length > 0 ? (
          <Content>
            <PageTitleContainer>
              <PageLocationTitle>Acessos Gerenciador</PageLocationTitle>
            </PageTitleContainer>

            <ProfileContainer>
              <ProfileList>               
                {myTournaments.length > 0 &&
                  myTournamentsList.map((buttons: any) => (
                    <ProfileButton
                      label={buttons.label}
                      icon={buttons.icon}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                    />
                  ))}
              </ProfileList>
            </ProfileContainer>
          </Content>
        ) : (
          <></>
        )}
        
        {account?.roles?.includes("manager") === true ? (
          <Content>
            <PageTitleContainer>
              <PageLocationTitle>Acessos Administrador</PageLocationTitle>
            </PageTitleContainer>

            <ProfileContainer>
              <ProfileList>
                {acessosAdm &&
                  account?.roles?.includes("manager") === true &&
                  acessosAdm.map((buttons: any) => (
                    <ProfileButton
                      label={buttons.label}
                      icon={buttons.icon}
                      url={buttons.url}
                      hasError={false}
                      error="Este campo é obrigatório"
                    />
                  ))}               
              </ProfileList>
            </ProfileContainer>
          </Content>
        ) : (
          <></>
        )}
      </Container>
      <Footer />
    </>
  );
};

export default ProfileEvent;
