import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";

import Input from "../../../components/atoms/input";
import Loading from "../../../components/atoms/loading";
import NavBar from "../../../components/organisms/navbar";
import Button from "../../../components/atoms/button";

import { FormContainer } from "../../enrollment/styles";
import { FormGroup, Content, PageTitle, ButtonAccessToken } from "./styles";
import { useNavigate, useParams } from "react-router-dom";

const EditBankAccount = () => {
    const { getToken } = useAuthenticate();
    const params = useParams();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    const onSubmit = (data: any) => {
        setDisabled(true);
        setLoading(true);

        const { name, descriptions } = data;

        api
            .put(
                "/manager/connect/bankAccount/" + params.id,
                {
                    name,
                    description: descriptions,
                    bankId: 1,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then((data: any) => {
                setTimeout(() => {
                    navigate(-1);
                }, 2000);
            })
            .catch(() => {
                setDisabled(false);
                setLoading(false);
                toast.error("Não foi possível a edição da conta do banco!");
            });
    };

    const onSubmitTokenAccess = () => {
        setDisabled(true);
        setLoading(true);
        api
            .get(
                `/manager/connect/authorization-url/${params.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then((data: any) => {
                setTimeout(() => {
                    window.open(data.data.url, "_self");
                }, 2000);
            })
            .catch(() => {
                setDisabled(false);
                setLoading(false);
                toast.error("Não foi possível a edição da conta do banco!");
            });
    };

    useEffect(() => {
        api
            .get(
                `/manager/connect/bankAccount/${params.id!}`,
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then((res) => {
                setValue("name", res.data.name);
                setValue("descriptions", res.data.description);
            });
    }, [])

    if (loading)
        return <Loading text="Aguarde, estamos processando seu cadastro." />;

    return (
        <>
            <NavBar />
            <Content>
                <PageTitle>Editar Conta de Banco</PageTitle>
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Input
                            label="Banco"
                            name="bank"
                            value={"PagBank".toUpperCase()}
                            type="text"
                            register={register}
                            required
                            hasError={!!errors.genre}
                            error="Este campo é obrigatório"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label="Nome"
                            name="name"
                            type="text"
                            register={register}
                            required
                            hasError={!!errors.name}
                            error="Este campo é obrigatório"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label="Descrição"
                            name="descriptions"
                            type="textarea"
                            register={register}
                            required={false}
                            hasError={!!errors.descriptions}
                            error="Este campo é obrigatório"
                        />
                    </FormGroup>
                    <ButtonAccessToken type="button" disabled={false} onClick={onSubmitTokenAccess}>Editar token de acesso</ButtonAccessToken>
                    <Button type="submit" label="Editar Banco" disabled={disabled} />
                </FormContainer>
            </Content>
        </>
    );
};

export default EditBankAccount;
