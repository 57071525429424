import { CircularProgress } from "@material-ui/core";
import { ValidationContainer, ValidationLink } from "./styles";
import { useEffect, useState } from "react";
import api from "src/services/api";
import useAuthenticate from "src/hooks/useAuthenticate";
import { useSearchParams } from "react-router-dom";
import { FaSmileWink, FaSadCry } from "react-icons/fa";
import NavBar from "src/components/organisms/navbar";

const ValidationConnectAccessToken = () => {
    const { getToken } = useAuthenticate();

    const [searchParams] = useSearchParams();
    const [result, setResult] = useState<boolean | null>(null);

    useEffect(() => {
        api
            .post(
                "/manager/connect/accessToken",
                {
                    code: searchParams.get("code"),
                    bankAccountId: Number(searchParams.get("state"))
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then(() => {

                setTimeout(() => {
                    setResult(true);
                }, 2000);
            })
            .catch(() => {
                setTimeout(() => {
                    setResult(false);
                }, 2000);
            });
    }, []);

    return <>
        <NavBar/>
        {result === null ?
            <ValidationContainer>
                <h1>Aguarde um momento...</h1>
                <h2>Estamos validando a sua conta!</h2>
                <CircularProgress size={250} style={{
                    marginTop: 32,
                    color: "black"
                }}></CircularProgress>
            </ValidationContainer> :
            <ValidationContainer>
                {result ? <>
                    <h1>Conta validada com sucesso!</h1>
                    <FaSmileWink size={250} />
                </> :
                    <>
                        <h1>Algo deu errado ao validar a conta!</h1>
                        <FaSadCry size={250} />
                        <h2>Por favor, tente novamente mais tarde ou entre em contato com nossa equipe!</h2>
                    </>
                }
                <ValidationLink href="/">Retorna para a página inicial!</ValidationLink>
            </ValidationContainer>

        }
    </>
}

export default ValidationConnectAccessToken;