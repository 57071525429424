import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "src/components/atoms/loading";
import NavBar from "src/components/organisms/navbar";
import useAuthenticate from "src/hooks/useAuthenticate";
import api from "src/services/api";
import { Content, FormGroup, PageTitle } from "./styles";
import { FormContainer } from "src/pages/enrollment/styles";
import Select from "src/components/atoms/select";
import Button from "src/components/atoms/button";

const EditTournamentAccount = () => {
    const { getToken } = useAuthenticate();
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()

    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [tournaments, setTournaments] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const onSubmit = (data: any) => {
        setDisabled(true);
        setLoading(true);

        const { tournament, account } = data;
        console.log(tournament, account)
        api
            .put(
                "/manager/connect/tournament/link",
                {
                    tournamentAccountId: Number(tournament),
                    bankAccountId: account === '0' ? null : Number(account),
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then((data: any) => {
                setTimeout(() => {
                    navigate(-1);
                }, 2000);
            })
            .catch(() => {
                setDisabled(false);
                setLoading(false);
                toast.error("Não foi possível a edição da conta do banco!");
            });
    };


    useEffect(() => {
        api
            .get(
                `/manager/connect/bankAccount`,
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then((res) => {
                const arrData = res.data.map((r: any) => { return { title: `${r.name} - ${r.bank.name}`, option: r.id } })
                arrData.unshift({title: `NENHUM`, option: 0});
                setAccounts(arrData);
            });
            
            api.get(
                "/managers/tournaments",
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            ).then((data) => {
                setTournaments(data.data.map((r: any) => { return { title: r.name, option: r.id } }));
            });
            setTimeout(() => {
                setValue("tournament", Number(searchParams.get("tournament")));
                setValue("account", Number(searchParams.get("account")));
              }, 100);
    }, [])

    if (loading)
        return <Loading text="Aguarde, estamos processando seu cadastro." />;

    return (
        <>
            <NavBar />
            <Content>
                <PageTitle>Vincular Conta de Banco em um torneio</PageTitle>
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Select
                            label="Torneio"
                            name="tournament"
                            register={register}
                            required
                            hasError={!!errors.tournament}
                            error="Este campo é obrigatório"
                            options={tournaments}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Select
                            label="Conta"
                            name="account"
                            register={register}
                            required
                            hasError={!!errors.account}
                            error="Este campo é obrigatório"
                            options={accounts}
                        />
                    </FormGroup>
                    <Button type="submit" label="Editar" disabled={disabled} />
                </FormContainer>
            </Content>
        </>
    );
};

export default EditTournamentAccount;
