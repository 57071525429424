import styled from "styled-components";

export const ValidationContainer = styled.div`
    width: 100%;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ValidationLink = styled.a`
  margin: 20px 15px 0 20px;
  cursor: pointer;
  background: rgb(55, 55, 55);
  border-radius: 8px;
  padding: 12px 18px;
  border: none;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  display: inline-block;
  text-decoration: none;
  margin-top: 14px;
  font-weight: bold;
  text-align: center;
  margin-top: 32px;
  min-width: 160px;
  @media (max-width: 370px) {
    width: 80%;
    text-align: center;
    min-width: auto;
  }
  @media (max-width: 450px) {
    width: 80%;
    text-align: center;
    min-width: auto;
  }
`;