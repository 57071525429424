import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../../components/atoms/button";
import Loading from "../../../components/atoms/loading";
import Select from "../../../components/atoms/select";
import NavBar from "../../../components/organisms/navbar";
import useAuthenticate from "../../../hooks/useAuthenticate";
import api from "../../../services/api";
import { Content, FormContainer, PageDescription, PageTitle } from "../styles";
import SelectFighters from "../../../components/atoms/selectFighters";
import SelectCategories from "src/components/atoms/selectCategories";
import rulesCopaAmizadeDeJiuJitsu2025 from "../../../data/rules-copa-amizade-de-jiu-jitsu-2025.json";
import rulesXviiiCopaParanaDeJiuJitsuNOGI from "../../../data/rules-xviii-copa-parana-de-jiu-jitsu-nogi.json";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core";
import Swal from "sweetalert2";

const EnrollmentCopaAmizadeDeJiuJitsu2025 = () => {
  const navigate = useNavigate();
  const { getToken, account } = useAuthenticate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();

  const comboValue = 150;
  const giFiled = 'valorInscricao';
  const noGiFiled = 'valorInscricao';

  const slugGI = "copa-amizade-de-jiu-jitsu-2025-umuarama-pr-gi";
  const slugNOGI = "";


  const modalidade = [
    { title: "Não", option: 0 },
    { title: "Sim", option: 1 },
  ];

  let { slug } = useParams();
  let rulesGI = rulesCopaAmizadeDeJiuJitsu2025;
  let rulesNOGI = rulesXviiiCopaParanaDeJiuJitsuNOGI;
  // let rules: any[] = []; */

  const [ages, setAges] = useState<any>([]);
  const [genre, setGenre] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [belts, setBelts] = useState<any>([]);
  const [weights, setWeights] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fighters, setFighters] = useState<any>([]);
  const [tournament, setTournament] = useState<any>({});
  const [modality, setModality] = useState<any>([]);
  const [enrollmentType, setEnrollmentType] = useState<String>("");
  const [categoriesNOGI, setCategoriesNOGI] = useState<any>([]);
  const [weightsNOGI, setWeightsNOGI] = useState<any>([]);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });


  const verifyCanSubscribe = async () => {

    const tournamentGI = (await api.get(`/tournaments/${slugGI}`)).data;

    const tournamentNOGI = (await api.get(`/tournaments/${slugNOGI}`)).data;

    return enrollmentType === 'combo' ? 
      tournamentGI!.canSubscribe && tournamentNOGI!.canSubscribe :
      enrollmentType === 'nogi' ? tournamentNOGI!.canSubscribe :
      enrollmentType === 'gi' ? tournamentGI!.canSubscribe : false;
  }

  const getTitleByOption = (array: any[], option: number) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].option === +option) {
        return array[i].title;
      }
    }
    return null;
  };

  const onSubmit = async (data: any) => {

    const canSubscribe = await verifyCanSubscribe();

    if(!canSubscribe){
      const msg = "Período de inscrição encerrado.";

      Swal.fire({
        title: 'Atenção!',
        text: msg,
        icon: 'error',
      });
  
      toast.error(msg);
      return
    }

    setDisabled(true);
    setLoading(true);

    const enrollmentIds: number[] = [];

    const arrBelts = [
      { title: "branca", option: 1 },
      { title: "cinza", option: 2 },
      { title: "amarela", option: 3 },
      { title: "laranja", option: 4 },
      { title: "verde", option: 5 },
      { title: "azul", option: 6 },
      { title: "roxa", option: 7 },
      { title: "marrom", option: 8 },
      { title: "preta", option: 9 },
    ];

    data.belt = getTitleByOption(arrBelts, data.belt);
    data.age = data.age.toString();
    delete data.fightingAge;

    if (enrollmentType === 'gi' || enrollmentType === 'combo') {
      // Verificando se `modalidade` é uma string ou número e ajustando a comparação
      const category =
        +data.gi.modalidade === 1
          ? "parajiu jitusu - " + data.gi.category
          : +data.gi.modalidade === 0
            ? data.gi.category
            : "";

      console.log(data);

      console.log({
        age: data.age,
        belt: data.belt,
        genre: data.genre,
        weight: data.gi.weight,
        category: category,
        fightersId: data.fightersId,
      });

      const valueSubscription = rulesGI.filter(r =>
        r.idade.min <= data.age &&
        r.idade.max >= data.age &&
        r.genero === data.genre &&
        r.faixa.some((f: any) => f.nome === data.belt) &&
        r.peso.descricao === data.gi.weight &&
        r.categoria === data.gi.category
      )[0];

      slug = "copa-amizade-de-jiu-jitsu-2025-umuarama-pr-gi"

      const dataEnrollment = await api
        .post(
          `${"/tournaments/enrollment/" + slug}`,
          {
            //...data,
            age: data.age,
            belt: data.belt,
            genre: data.genre,
            weight: data.gi.weight,
            category: category,
            fightersId: +data.fightersId,
            valueSubscription: enrollmentType === 'combo' ? String(comboValue / 2) : String(valueSubscription[giFiled])
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        )

      enrollmentIds.push(dataEnrollment.data.id);
    }

    if (enrollmentType === 'nogi' || enrollmentType === 'combo') {
      // Verificando se `modalidade` é uma string ou número e ajustando a comparação
      const category =
        +data.nogi.modalidade === 1
          ? "parajiu jitusu - " + data.nogi.category
          : +data.nogi.modalidade === 0
            ? data.nogi.category
            : "";

      console.log(data);

      console.log({
        age: data.age,
        belt: data.belt,
        genre: data.genre,
        weight: data.nogi.weight,
        category: category,
        fightersId: data.fightersId,
      });

      const valueSubscription = rulesNOGI.filter(r =>
        r.idade.min <= data.age &&
        r.idade.max >= data.age &&
        r.genero === data.genre &&
        r.faixa.some((f: any) => f.nome === data.belt) &&
        r.peso.descricao === data.nogi.weight &&
        r.categoria === data.nogi.category
      )[0];

      slug = "";

      const dataEnrollment = await api
        .post(
          `${"/tournaments/enrollment/" + slug}`,
          {
            //...data,
            age: data.age,
            belt: data.belt,
            genre: data.genre,
            weight: data.nogi.weight,
            category: category,
            fightersId: +data.fightersId,
            valueSubscription: enrollmentType === 'combo' ? String(comboValue / 2) : String(valueSubscription[noGiFiled])
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          }
        );

        enrollmentIds.push(dataEnrollment.data.id);
    }

    if(tournament.paymentManual){

      toast.success("A sua pre-inscrição foi realizada com sucesso!");

      setTimeout(() => {
        navigate("/payment-instruction/" + slug + "/" + enrollmentIds.toString().replace(",", "-"));
      }, 2000);

      return
    }

    api
      .post(
        "/payments/checkout",
        {
          enrollmentId: enrollmentIds
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        }
      )
      .then((res: any) => {
        toast.success("A sua pre-inscrição foi realizada com sucesso!");
        const width = 800; // Largura da nova janela
        const height = 600; // Altura da nova janela
        const left = (window.screen.width / 2) - (width / 2); // Calcula a posição horizontal
        const top = (window.screen.height / 2) - (height / 2); // Calcula a posição vertical

        if (windowSize.width < width) {
          window.open(res.data.links[1].href, "_self")
        } else {
          window.open(res.data.links[1].href, "_self")
        }
      }).catch((error) => {
        setDisabled(false);
        setLoading(false);
        toast.error(
          error.response.data.message ||
          "Não foi possível gerar a sua pré-inscrição!"
        );
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Limpeza do evento ao desmontar o componente
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    api
      .get("/fighters", {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      })
      .then(({ data }) => {
        setLoading(false);
        setFighters([
          {
            id: "",
            name: "Selecione uma opção",
          },
          {
            id: 0,
            name: `${account.name}`,
          },
          ...data,
        ]);
      })
      .catch(() => {
        setLoading(false);
        toast.error("Não foi carregar os dados.");
      });

    api.get("tournaments/" + slug).then(({ data }) => {
      console.log("TORNANMENY", data)
      setTournament(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  let acc: any = account;
  let formattedGenre: String;

  let weightsSerialize: any = [];
  let weightsSerializeNOGI: any = [];
  let categoriesSerialize: any = [];
  let categoriesSerializeNOGI: any = [];

  let arrCategories: any = [];
  let arrCategoriesNOGI: any = [];
  let arrWeights: any = [];
  let arrWeightsNOGI: any = [];

  let fightingAge: Number;

  const handleChange = (e: any) => {
    setValue("category", "");
    setValue("weight", "");
    console.log("qqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqq");
    console.log(">>>>>>>>", modalidade);
    setValue("modalidade", "");

    setCategories([]);
    setCategoriesNOGI([]);
    setWeights([]);
    setWeightsNOGI([]);
    setModality([]);
    // setModality([
    //   {
    //     title: "Selecione uma opção",
    //     option: "",
    //   },
    //   ...modalidade,
    // ]);

    let genre: any = [];
    let belts: any = [];

    const arrGenre = [
      { title: "Masculino", option: "masculino" },
      { title: "Feminino", option: "feminino" },
    ];

    const arrBelts = [
      { title: "Branca", option: 1 },
      { title: "Cinza", option: 2 },
      { title: "Amarela", option: 3 },
      { title: "Laranja", option: 4 },
      { title: "Verde", option: 5 },
      { title: "Azul", option: 6 },
      { title: "Roxa", option: 7 },
      { title: "Marrom", option: 8 },
      { title: "Preta", option: 9 },
    ];
    // eslint-disable-next-line eqeqeq
    if (e.target.value == 0) {
      //============================== Genero ==============================
      genre = arrGenre.filter((t) => t.option.toLowerCase() === acc.genre.toLowerCase());

      formattedGenre = genre[0]?.option.toLowerCase();
      //============================== Idade ==============================
      // donst work with a valid date
      // // donst work with a valid date
      // const dateOfTournament = +tournament.date.replace(/\//g, "-").split("-")[0];

      const dateOfTournament = new Date().getFullYear();
      const birthFightFromFighter = +acc.birth_date.replace(/\//g, "-").split("-")[2];
      fightingAge = dateOfTournament - birthFightFromFighter;

      //============================== Faixa ==============================
      belts = arrBelts.filter((b) => b.option === acc.belt);
      //============================== Categoria ==============================
      // eslint-disable-next-line array-callback-return
      rulesGI.map((item) => {
        if (
          item.idade.max >= +fightingAge &&
          item.idade.min <= +fightingAge &&
          item.genero === formattedGenre &&
          item.faixa.filter(
            (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
          ).length
        ) {
          arrCategories.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
            descricaoCompleta: item.idade.descricaoCompleta,
          });
        }
      });

      rulesNOGI.map((item) => {
        if (
          item.idade.max >= +fightingAge &&
          item.idade.min <= +fightingAge &&
          item.genero === formattedGenre &&
          item.faixa.filter(
            (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
          ).length
        ) {
          arrCategoriesNOGI.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
            descricaoCompleta: item.idade.descricaoCompleta,
          });
        }
      });

      let uniqueArray = [];
      let itemSet = new Set();

      for (const obj of arrCategories) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerialize = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            //`${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
            `${category.item} - ${category.descricaoCompleta}`.toUpperCase(),
          option: category.item,
        };
      });

      uniqueArray = [];
      itemSet = new Set();

      for (const obj of arrCategoriesNOGI) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerializeNOGI = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            //`${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
            `${category.item} - ${category.descricaoCompleta}`.toUpperCase(),
          option: category.item,
        };
      });

      setValue("age", +fightingAge);
      setValue("category", "");
    } else {
      // ============================== Fighters ==============================
      // eslint-disable-next-line eqeqeq
      const fightersFilter: any = fighters.filter((f: any) => +f.id === +e.target.value);
      //============================== Genero ==============================
      genre = arrGenre.filter(
        (t) => t.title.toLowerCase() === fightersFilter[0].genre.toLowerCase()
      );

      formattedGenre = genre[0].title.toLowerCase();
      //============================== Idade ==============================

      // donst work with a valid date
      // const dateOfTournament = +tournament.date.replace(/\//g, "-").split("-")[0];
      const dateOfTournament = new Date().getFullYear();
      const birthFightFromFighter = +fightersFilter[0].birth_date
        .replace(/\//g, "-")
        .split("-")[2];
      fightingAge = dateOfTournament - birthFightFromFighter;
      //============================== Faixa ==============================
      belts = arrBelts.filter((b) => b.option === fightersFilter[0].belt);
      //============================== Categoria ==============================
      // eslint-disable-next-line array-callback-return
      rulesGI.map((item) => {
        if (
          item.idade.max >= +fightingAge &&
          item.idade.min <= +fightingAge &&
          item.genero === formattedGenre &&
          item.faixa.filter(
            (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
          ).length
        ) {
          arrCategories.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
            descricaoCompleta: item.idade.descricaoCompleta,
          });
        }
      });

      rulesNOGI.map((item) => {
        if (
          item.idade.max >= +fightingAge &&
          item.idade.min <= +fightingAge &&
          item.genero === formattedGenre &&
          item.faixa.filter(
            (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
          ).length
        ) {
          arrCategoriesNOGI.push({
            item: item.categoria,
            min: item.idade.min,
            max: item.idade.max,
            maxDescricao: item.idade.maxParaDescricao,
            minDescricao: item.idade.minParaDescricao,
            descricaoCompleta: item.idade.descricaoCompleta,
          });
        }
      });

      let uniqueArray = [];
      let itemSet = new Set();

      for (const obj of arrCategories) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerialize = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            //`${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
            `${category.item} - ${category.descricaoCompleta}`.toUpperCase(),
          option: category.item,
        };
      });

      uniqueArray = [];
      itemSet = new Set();

      for (const obj of arrCategoriesNOGI) {
        if (!itemSet.has(obj.item)) {
          itemSet.add(obj.item);
          uniqueArray.push(obj);
        }
      }

      categoriesSerializeNOGI = [...new Set(uniqueArray)].map((category: any) => {
        return {
          title:
            //`${category.item} - ${category.minDescricao} até ${category.maxDescricao} anos`.toUpperCase(),
            `${category.item} - ${category.descricaoCompleta}`.toUpperCase(),
          option: category.item,
        };
      });

      setValue("category", "");
    }

    setGenre([...genre]);
    setAges([{ title: fightingAge, option: fightingAge }]);
    setBelts([...belts]);
    setTimeout(() => {
      setValue("genre", genre[0]?.option);
      setValue("age", fightingAge);
      setValue("belt", belts[0]?.option);
    }, 100);
    setCategories([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...categoriesSerialize,
    ]);
    setCategoriesNOGI([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...categoriesSerializeNOGI,
    ]);
  };

  //============================== Peso ==============================
  const handleChangeWeights = (e: any) => {
    setValue("gi.modalidade", "");
    setValue("gi.weight", "");
    // eslint-disable-next-line array-callback-return
    rulesGI.map((item) => {
      if (
        item.idade.max >= +ages[0].option &&
        item.idade.min <= +ages[0].option &&
        item.genero === genre[0].title.toLowerCase() &&
        item.categoria === e.target.value &&
        item.faixa.filter(
          (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
        ).length
      ) {
        arrWeights.push({
          peso: item.peso.descricao,
          massa: item.peso.max,
        });
      }
    });

    weightsSerialize = [...new Set(arrWeights)].map((category: any) => {
      console.log(category);
      return {
        title: `${category.peso} - ${category.massa} `.toUpperCase(),
        option: category.peso,
      };
    });

    setWeights([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...weightsSerialize,
    ]);
  };

  const handleChangeWeightsNOGI = (e: any) => {
    setValue("nogi.weight", "");
    setValue("nogi.modalidade", "");
    // eslint-disable-next-line array-callback-return

    rulesNOGI.map((item) => {
      if (
        item.idade.max >= +ages[0].option &&
        item.idade.min <= +ages[0].option &&
        item.genero === genre[0].title.toLowerCase() &&
        item.categoria === e.target.value &&
        item.faixa.filter(
          (f: any) => f.nome.toLowerCase() === belts[0].title.toLowerCase()
        ).length
      ) {
        arrWeightsNOGI.push({
          peso: item.peso.descricao,
          massa: item.peso.max,
        });
      }
    });

    weightsSerializeNOGI = [...new Set(arrWeightsNOGI)].map((category: any) => {
      console.log(category);
      return {
        title: `${category.peso} - ${category.massa}`.toUpperCase(),
        option: category.peso,
      };
    });

    setWeightsNOGI([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...weightsSerializeNOGI,
    ]);
  };

  const handleChangeClasses = (e: any) => {
    console.log(">>>>>testfunc>>>>>>>", modalidade);
    setModality([
      {
        title: "Selecione uma opção",
        option: "",
      },
      ...modalidade,
    ]);
  };


  if (loading)
    return <Loading text="Aguarde, estamos processando a sua pré-inscrição." />;
  console.log(">>>>>>>>>>>modality>>>>>>>", modality);
  return (
    <div>
      <NavBar />

      <Content>
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <PageTitle>Inscrição</PageTitle>
          <PageDescription>
            {/* Essa inscrição é referente ao torneio: {tournament.name} */}
            Essa inscrição é referente ao torneio: Copa Amizade de Jiu Jitsu 2025
          </PageDescription>

          <SelectFighters
            label="Escolha um atleta para esse evento"
            name="fightersId"
            register={register}
            hasError={!!errors.category}
            required
            error="Este campo é obrigatório"
            options={fighters}
            onChange={handleChange}
          />

          <Select
            label="Sexo"
            name="genre"
            register={register}
            required
            hasError={!!errors.genre}
            error="Este campo é obrigatório"
            options={genre}
          />

          <Select
            label="Idade de luta"
            name="age"
            register={register}
            required
            hasError={!!errors.age}
            error="Este campo é obrigatório"
            options={ages}
          />

          <Select
            label="Faixa"
            name="belt"
            register={register}
            required
            hasError={!!errors.belt}
            error="Este campo é obrigatório"
            options={belts}
          />

          <FormControl style={{ marginBottom: '24px' }}>
            <FormLabel id="demo-row-radio-buttons-group-label" style={{ color: "#33303E" }}>Tipo da Inscrição</FormLabel>
            <RadioGroup
              aria-label="Filtrar por"
              value={enrollmentType}
              onChange={(e: any) => {
                setEnrollmentType(e.target.value);
                setValue("gi.category", "");
                setValue("gi.weight", "");
                setValue("gi.modalidade", "");
                setValue("nogi.category", "");
                setValue("nogi.weight", "");
                setValue("nogi.modalidade", "");
                setWeights([]);
                setWeightsNOGI([]);
                setModality([]);
              }}
              row
            >
              <FormControlLabel
                value="gi"
                control={<Radio />}
                label="GI"
              />
             {/*  <FormControlLabel
                value="nogi"
                control={<Radio />}
                label="NOGI"
              />
              <FormControlLabel
                value="combo"
                control={<Radio />}
                label="COMBO (GI + NOGI)"
              /> */}
            </RadioGroup>
          </FormControl>

          {
            (enrollmentType === 'gi' || enrollmentType === 'combo') &&
            <>
              <PageTitle>GI</PageTitle>

              <SelectCategories
                label="Categoria"
                name="gi.category"
                register={register}
                required
                hasError={!!errors.category}
                error="Este campo é obrigatório"
                options={categories}
                onChange={handleChangeWeights}
              />

              <SelectCategories
                label="Peso"
                name="gi.weight"
                register={register}
                required
                hasError={!!errors.category}
                error="Este campo é obrigatório"
                options={weights}
                onChange={handleChangeClasses}
              />

              <SelectCategories
                label="Pessoa com deficiência - PcD?"
                name="gi.modalidade"
                register={register}
                required
                hasError={!!errors.modalidade}
                error="Este campo é obrigatório"
                options={modality}
              />
            </>
          }

          {
            (enrollmentType === 'nogi' || enrollmentType === 'combo') &&
            <><PageTitle>NOGI</PageTitle>

              <SelectCategories
                label="Categoria"
                name="nogi.category"
                register={register}
                required
                hasError={!!errors.category}
                error="Este campo é obrigatório"
                options={categoriesNOGI}
                onChange={handleChangeWeightsNOGI}
              />

              <SelectCategories
                label="Peso"
                name="nogi.weight"
                register={register}
                required
                hasError={!!errors.category}
                error="Este campo é obrigatório"
                options={weightsNOGI}
                onChange={handleChangeClasses}
              />

              <SelectCategories
                label="Pessoa com deficiência - PcD?"
                name="nogi.modalidade"
                register={register}
                required
                hasError={!!errors.modalidade}
                error="Este campo é obrigatório"
                options={modality}
              /></>}

          <Button type="submit" label="Inscrever" disabled={disabled} />
        </FormContainer>
      </Content>
    </div>
  );
};

export default EnrollmentCopaAmizadeDeJiuJitsu2025;
