import { useState } from "react";
import api from "../../../services/api";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import useAuthenticate from "../../../hooks/useAuthenticate";

import Input from "../../../components/atoms/input";
import Loading from "../../../components/atoms/loading";
import NavBar from "../../../components/organisms/navbar";
import Button from "../../../components/atoms/button";

import { FormContainer } from "../../enrollment/styles";
import { FormGroup, Content, PageTitle } from "./styles";
import Select from "src/components/atoms/select";

const NewBankAccount = () => {
    const { getToken } = useAuthenticate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [disabled, setDisabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (data: any) => {
        setDisabled(true);
        setLoading(true);

        const { name, descriptions } = data;

        api
            .post(
                "/manager/connect/bankAccount",
                {
                    name,
                    description: descriptions,
                    bankId: 1,
                },
                {
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                    },
                }
            )
            .then((data: any) => {
                api
                    .get(
                        `/manager/connect/authorization-url/${data.data.id!}`,
                        {
                            headers: {
                                Authorization: `Bearer ${getToken()}`,
                            },
                        }
                    )
                    .then((res) => {
                        toast.success("A conta do banco foi cadastrada com sucesso!");

                        setTimeout(() => {
                            window.open(res.data.url, "_self");
                        }, 2000);
                    })
            })
            .catch(() => {
                setDisabled(false);
                setLoading(false);
                toast.error("Não foi possível o cadastro da conta do banco!");
            });
    };

    if (loading)
        return <Loading text="Aguarde, estamos processando seu cadastro." />;

    return (
        <>
            <NavBar />
            <Content>
                <PageTitle>Cadastro Conta de Banco</PageTitle>
                <FormContainer onSubmit={handleSubmit(onSubmit)}>
                    <FormGroup>
                        <Select
                            label="Banco"
                            name="bank"
                            register={register}
                            required
                            hasError={!!errors.genre}
                            error="Este campo é obrigatório"
                            options={[
                                { title: "PagBank".toUpperCase(), option: 1 },
                            ]}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label="Nome"
                            name="name"
                            type="text"
                            register={register}
                            required
                            hasError={!!errors.name}
                            error="Este campo é obrigatório"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Input
                            label="Descrição"
                            name="descriptions"
                            type="textarea"
                            register={register}
                            required={false}
                            hasError={!!errors.descriptions}
                            error="Este campo é obrigatório"
                        />
                    </FormGroup>
                    <Button type="submit" label="Cadastrar Banco" disabled={disabled} />
                </FormContainer>
            </Content>
        </>
    );
};

export default NewBankAccount;
