import styled from "styled-components";

export const ConnectAccountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
    max-height: 300px;
    min-height: 300px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 8px 16px;
    cursor: pointer;
`;

export const ConnectAccountImage = styled.img`
    width: 80%;
    height: 70px;
    object-fit: cover;
`;

export const ConnectAccountArticle = styled.article`
    width: 100%;
`;