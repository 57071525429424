import NavBar from "src/components/organisms/navbar";
import { Container, Content, PageLocationTitle, PageTitleContainer } from "../styles";
import { useEffect, useState } from "react";
import api from "src/services/api";
import useAuthenticate from "src/hooks/useAuthenticate";
import { LinkedBankTournamentsContainer } from "./styles";
import TournamentsAccount from "./components/TournamentsAccount";

const LinkedBankTournaments = () => {
    const { getToken } = useAuthenticate();
    const [tournaments, setTournaments] = useState([]);

    useEffect(() => {
        api.get(
            "/managers/tournaments",
            {
                headers: {
                    Authorization: `Bearer ${getToken()}`,
                },
            }
        ).then((data) => {
            setTournaments(data.data);
        });
    }, []);

    return <>
        <NavBar />
        <Container>
            <Content>
                <PageTitleContainer>
                    <PageLocationTitle>Gerenciar Torneios e Contas</PageLocationTitle>
                </PageTitleContainer>

                <LinkedBankTournamentsContainer>
                    
                        {
                            tournaments.sort((a: any, b: any) => b.id - a.id).map((t: any) => (
                                    <TournamentsAccount key={t.id}  tournament={t} />
                            ))
                        }
                    
                
                </LinkedBankTournamentsContainer>
            </Content>
        </Container>
    </>
}

export default LinkedBankTournaments;